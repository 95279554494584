@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300&family=Varela+Round&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-container {
  width: 100%;
  max-width: 1140px; /* This is an example, adjust as per your primary container's width */
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px; /* This is an example, adjust as per your primary container's padding */
  padding-right: 15px; /* This is an example, adjust as per your primary container's padding */
}

@media (max-width: 991px) {
  .navbar-container {
      max-width: 960px; /* Adjust as per your primary container's responsive width */
  }
}
@media (max-width: 767px) {
  .navbar-container {
      max-width: 720px; /* Adjust as per your primary container's responsive width */
  }
  .mobile_text {
    max-width: 80% !important; /* Sets the maximum width to full width of the parent */
    display: block !important;  /* Ensures the text takes up the full width */
  }
}

