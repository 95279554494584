.App {
  text-align: center;
}
body{
  /* background-color: #05050B; */
  background-color: black;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.app__navbar {
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  /* padding: 0 5%; */
  /* background-color: white; */
  /* padding: 0 8rem; */
  /* width: 100%; */
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(12px);
  border-radius: 24px;
  /* height: 100px; */
  display: flex;
  align-items: center;
  /* margin-bottom: 100px; */
  /* justify-content: space-between; */
  /* padding: 24px; */
  /* flex-wrap: wrap; */
  transition: all 0.7s ease-out;

/* z-index: 1 !important; */
left: 50%; /* center the element */
transform: translateX(-50%); /* pull back 50% of its own width to truly center */
max-width: 1319px; 
}
.margin{
  margin-top: 100px;
}
.margin_extra{
  margin-top: 150px;
}



.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;

}

/* .app__navbar-logo img {
  width: 150px;
} */

.app__navbar-links {
  flex: 1;
  display: flex;
  /* justify-content: center; */
  align-items: center;

  list-style: none;
}

.app__navbar-links li {
  margin: 0 1rem;
  cursor: pointer;
  /* font-family: var(--font-base); */

}

.app__navbar-links a,.colr {
  text-decoration: none;
  /* background: linear-gradient(135deg, #b38728, #fcf6ba, #bf953f, #fbf5b7, #aa771c); */
/* -webkit-background-clip: text; */
/* -webkit-text-fill-color: transparent; */
/* background: linear-gradient(135deg, #E89100, #C47801, #925300); */
color:white;
/* -webkit-background-clip: text; */
/* background-clip: text; */
/* color: transparent; */

}

.app__navbar-links li:hover {
    color: #be1f6f;

}

.app__navbar-login {
  display: flex;
  justify-content: flex-end;
  align-items: center;

}





.app__navbar-smallscreen {
  display: none; 
  z-index: 9999 !important;
}

.app__navbar-smallscreen_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* background: #000; */
  transition: .5s ease;
  /* background: rgba(255, 255, 255, 0.02); */
  background-color: black;
  transition: all 0.7s ease-out;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  border-radius: 24px;
  /* height: 100px; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  /* padding: 24px; */
  /* flex-wrap: wrap; */
  transition: all 0.7s ease-out;
  flex-direction: column;
  z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
  font-size: 27px;
  color: #ffff;
  cursor: pointer;

  position: absolute;
  top: 20px;
  right: 20px;
}
.bg_hero{
  /* background-image: url('../src/assets/herosectionbg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 700px;
  margin-top: -60px; */
  /* width: 80%; */
  /* position:absolute; */
}
.img_hero{
  /* position: relative; */
}
.hero_img{
  /* width: 80%; */
  position: absolute;
}
.hero__object {
  animation: zoom ease-out;
  animation-delay: 0.4s;
  animation-duration: 1.5s;
  top: -250px;
  left: 400px;
  z-index: -1;
}
.circle {
  width: 240px;
  height: 240px;
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(57.75deg, #F70FFF 14.44%, #2C63FF 85.65%);
}
.circle_about::before {
  content: '';
  width: 100%;
  top: initial;
  bottom: initial;
  left: initial;
  right: initial;
  position: absolute;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(57.75deg, #F70FFF 14.44%, #2C63FF 85.65%);
  opacity: 0.84;
  filter: blur(100px);
}
.circle_about{
  animation: circlethree 10s ease-in-out infinite;
  width: 240px;
  height: 240px;
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(57.75deg,#2C63FF 14.44%,  #F70FFF  85.65%);
  top: 207px;
  left: 150px;
  z-index: -1;
}
@keyframes circlethree {
  0%, 100% {
      transform: translate(0, 0);
  }
  25% {
      transform: translate(20px, 20px);
  }
  50% {
      transform: translate(20px, 20px);
  }
  75% {
      transform: translate(-10px, 10px);
  }
}
@keyframes circletwo {
  0%, 100% {
      transform: translate(0, 0);
  }
  25% {
      transform: translate(-10px, -10px);
  }
  50% {
      transform: translate(0, -20px);
  }
  75% {
      transform: translate(10px, -10px);
  }
}

.circletwo {
  animation: circletwo 15s ease-in-out infinite;
  border-radius: 50%;
  /* width: 250px;
  height: 250px; */
  background-color: red;  /* Different color for distinction */
}
.about_img {
  animation: circletwo 10s ease-in-out infinite;
  border-radius: 50%;
  /* width: 240px;
  height: 240px; */
  width: 266px;
    height: 266px;
    margin-top: 112px;
}
.app__navbar-smallscreen_links {
  list-style: none;
  color: #fff;
  /* font-size: 20px; */
  height: 100vh;
  overflow-y: scroll;
}

.app__navbar-smallscreen_links li {
  margin: 2rem;
  cursor: pointer;
  /* color: #fff; */
/* background: linear-gradient(135deg, #E89100, #C47801, #925300); */
/* -webkit-background-clip: text; */
/* background-clip: text; */
color: white;
  text-decoration: none;
  font-size: 2rem;
  text-align: center;
  /* font-family: var(--font-base); */
}

.app__navbar-smallscreen_links a {
  text-decoration: none;
  font-size: 18px;
  /* background: linear-gradient(135deg, #b38728, #fcf6ba, #bf953f, #fbf5b7, #aa771c); */
/* -webkit-background-clip: text; */
/* color: black; */
/* -webkit-text-fill-color: transparent; */

}


.btn_main{
  border: transparent !important;
  border-radius: 34.5px !important;
  padding: 10px 0 10px 0 !important;
  width: 200px;
  font-size: 1rem !important;
  font-weight: 500 !important;
  box-shadow: -6px -6px 10px rgba(253,251,211,.2), 6px 6px 10px rgba(0,0,0,.8);
  margin-inline: .5rem;
  color: #ffff;
  display: block;

  /* background: linear-gradient(to right, 135deg, #b38728, #fcf6ba, #bf953f, #fbf5b7, #aa771c 50%, white 50%); */
background: linear-gradient(135deg, #b38728, #fcf6ba, #bf953f, #fbf5b7, #aa771c);

  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .5s ease-out;
}
.button_all{
  cursor: pointer;
  border: transparent !important;
  border-radius: 34.5px !important;
  padding: 10px 0 10px 0 !important;
  width: 200px;
  font-size: 1rem !important;
  font-weight: 500 !important;
  box-shadow: -6px -6px 10px rgba(253,251,211,.2), 6px 6px 10px rgba(0,0,0,.8);
  margin-inline: .5rem;
  color: #ffff;
  display: block;

  /* background: linear-gradient(to right, 135deg, #b38728, #fcf6ba, #bf953f, #fbf5b7, #aa771c 50%, white 50%); */
background: linear-gradient(135deg, #b38728, #fcf6ba, #bf953f, #fbf5b7, #aa771c);

  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .5s ease-out;
}
.button {
  cursor: pointer;
border: transparent !important;
  border-radius: 34.5px !important;
  padding: 10px 0 10px 0 !important;
  width: 200px;
  font-size: 1rem !important;
  font-weight: 500 !important;
  box-shadow: -6px -6px 10px rgba(253,251,211,.2), 6px 6px 10px rgba(0,0,0,.8);
  margin-inline: .5rem;
  color: #ffff;
  display: block;

  /* background: linear-gradient(to right, 135deg, #b38728, #fcf6ba, #bf953f, #fbf5b7, #aa771c 50%, white 50%); */
background: linear-gradient(135deg, #b38728, #fcf6ba, #bf953f, #fbf5b7, #aa771c);

  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .5s ease-out;
}

.button:hover {
  background-position: left bottom;
  color: #000 !important;
}
.btn_main:hover{
  background-position: left bottom;
  color: #000 !important;
}
.text {
  text-align: center;
  font-size: 16px;
  line-height: 30px; 
  color: black !important;
  transition: all .6s ease-out;
  display: block;
}

.text:hover {
  color: black !important;  
}

.app__navbar-smallscreen_links li:hover {
  color: #be1f6f;

}

@media screen and (min-width: 2000px) {
  .app__navbar-logo img {
      width: 210px;
  }
}

@media screen and (max-width: 1200px) {
  .app__navbar-links {
      display: none;
  }

  .app__navbar-smallscreen {
      display: flex;
  }

  .app__navbar-links a {
      text-decoration: none;
      color: #fff;

  }

  .btn-mob {
      display: none;
  }

  .md_btn {
      display: none;
  }

}

@media screen and (max-width: 650px) {

  .app__navbar {
      padding: 10px;
      height: 90px;
  }

  .app__navbar-login {
      font-size: 8px;


  }

  .app__navbar-logo img {
      width: 115px;
  }

  .app__navbar-links a {
      text-decoration: none;
      color: #fff;

  }

  .btn-mob {
      display: none;
  }
  .button {
      width: 115px;
       padding: 3px 0 3px 0 !important;
  }
  .btn_main{
      width: 75%;
  }
  .button_all{
      width: 153px ;
      padding: 3px 0 3px 0 !important;

  }
}


/* .active {
  color: #0ab0a5 !important;
  font-weight: bold;
} */




.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navlink:hover{
   text-decoration: underline;
   cursor: pointer;
}
.hover{
  text-decoration: underline;
  cursor: pointer;
}
/* CSS */
.button-19,.button-191 {
  /* appearance: button; */
  /* background-color: #1899D6; */
/* background: linear-gradient(135deg, #E89100, #C47801, #925300);
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: din-round,sans-serif;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: .8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter .2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%; */
  background: linear-gradient(57.75deg, #F70FFF 14.44%, #2C63FF 85.65%);
    width: 204px;
    height: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    border: none;
    
}
.hero_text,.hero_text2{
  background: linear-gradient(57.75deg, #F70FFF 14.44%, #2C63FF 85.65%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* font-size: 120px; */
    position: relative;

  
    
}
.table_text th {
  color: white ;

}
.table_text td {
  color: white ;
  
}
.place{
  font-size: 13px;
  color: gray;
}
.hero_text:before {
  content: attr(title);
  position: absolute;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-stroke: 2px #932cff;
  top: -33px;
  left: 0;
  right: 0;
  /* z-index: -1; */
  height: 80px;
  overflow: hidden;
  opacity: 0.35;
}
.hero_text_p{
  font-family: 'Urbanist', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #A7A3B5;
    line-height: 36px;
}
.hero_text:after{
  content: attr(title);
  position: absolute;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-stroke: 2px #3f0fff;
  top: -74px;
  left: 0;
  right: 0;
  /* z-index: -2; */
  height: 80px;
  overflow: hidden;
  opacity: 0.27;
}
.hero_text_font{
  font-size: 100px;
  margin-top: 80px;
  /* font-family: 'Varela Round', sans-serif; */
  font-family: 'Kanit', sans-serif;
  font-weight: 700;
}
.btn_shadow{
  box-shadow: 0 16px 24px rgb(247 15 255 / 48%);
}
.btn_explore{
  text-decoration: none;
  color: white;
  cursor: pointer;
}
.button-191 span{
  display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background: #06060C;
    width: 200px;
    height: 46px;
    position: absolute;
    color: white;
    border-radius: 24px;
    border: none;
    outline: none;
}
.box{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 186px;
  border: 1px solid grey;
  backdrop-filter: blur(2px);
  border-radius: 24px;
  color: white;
  /* gap: 16px; */
}
.box h2{
  font-size: 45px;
}
.nft_p{
  font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.64)
}
.cards{
  /* width: 100%; */
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    /* background-color: rgba(58, 51, 51, 0.02); */
    /* background: rgba(255, 255, 255, 0.02); */
    /* background-color: #2C63FF; */
    /* backdrop-filter: blur(12px); */
    /* background-color: #282c34; */
    border: 1px solid grey;
    /* border-radius: 24px; */
    border-radius: 15px;
}
.cards_mission{
  /* width: 100%; */
  margin-bottom: 12px;
  display: flex;
  align-items: flex-start;
  /* justify-content: space-between; <-- remove this */
  flex-direction: column;
  /* other styles remain unchanged */
  border: 1px solid grey;
  border-radius: 24px;
}
.bg_nfts{
  background-image: linear-gradient(180deg, #06060C 0%, rgba(6, 6, 12, 0.01) 47.37%, rgba(6, 6, 12, 0.84) 73%, #06060C 100%), url('../src/assets/collection_background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 750px; */
  /* margin-bottom: 100px; */
  /* padding: 80px; */
  /* width: 100%; */
}
.about_p{
  font-family: 'Urbanist', sans-serif;
    font-style: normal;
    font-weight: 500;
    /* font-size: 20px; */
    color: #A7A3B5;
    /* line-height: 36px; */
    text-align: justify;
}
.img_about_us{
  position: relative;
    z-index: 2;
    max-width: unset!important;
    height: unset!important;
   
}
.cards h5{
  font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: white;
    padding: 14px;
    background: linear-gradient(57.75deg, #F70FFF 14.44%, #2C63FF 85.65%);
    text-align: center;
    width: 95%;
    margin: 0 auto;
    border-radius: 10px;
}
.pool_bg1{
  font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: white;
    padding: 14px;
    background: linear-gradient(57.75deg, #F70FFF 14.44%, #2C63FF 85.65%);
    text-align: center;
    width: 95%;
    margin: 0 auto;
    border-radius: 10px;
}
.text_nfts{
  font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    color: white;
}
.text_nftss{
  font-weight: 700;
  font-size: 25px;
  /* line-height: 56px; */
  color: white;
}
.radius_0{
  border-radius: 20px !important;
  height: 400px;
    margin: 0 auto;
    display: block;
}
.border_footer{
  border-top: 1px solid white;
}
.align_p p{
  text-align: justify;
}
.box p{
  margin-bottom: 0px;
  font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.64);
}
input::placeholder {
  color: white;
  opacity: 1; /* Required for some browsers to ensure the color is fully opaque */
}

.card{
  display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.02);
    /* backdrop-filter: blur(12px); */
    border-radius: 24px;
    border: 1px solid white;
  /* border-radius: 10px !important; */
}
.img_size{
  height: 400px;
  width: 400px;
  border-radius: 15px;
  margin-bottom: 10px;
}
.line_none{
  line-height: 35px !important;
}
/* .button-191::after{
  background-clip: padding-box;
background: linear-gradient(135deg, #E89100, #C47801, #925300);
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
} */
/* .button-19:after {
  background-clip: padding-box;
background: linear-gradient(135deg, #E89100, #C47801, #925300);
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
} */
h3{
  color: #C47701;
}
.color{
  color: #C47701;

}
.button-19,.button-191:main,
.button-19,.button-191:focus {
  user-select: auto;
}
.button-19:hover:not(:disabled) {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.button-191:hover:not(:disabled) {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}
.logo{
  width: 300px;
  height: 80px;
}
.button-19,.button-191:disabled {
  cursor: auto;
}
.active{
  color: white !important;
  font-weight: bold;
}
.clr_bnb{
  color: #A7A3B5;
}
@media (max-width:768px){
  .hero_text_font{
    font-size: 50px;
  }
  .box{
    margin-bottom: 20px;
  }
}
@media (max-width:600px){
  .mobile_nfts{
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 20px;
  }
  .img_size{
    width: auto;
    height: 300px;
    margin: 0 auto;
  }
  .img_about_us{
    justify-content: center;
    display: flex;
  }
  .hero_text_font{
    font-size: 46px;
  }
  .mobile{
    /* position: relative !important; */
    /* left: 0px; */
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
    /* z-index: -1; */
  }
  .mobile_btn{
    margin: 0 auto;
  }
  .hero_text:after {
    content: attr(title);
    position: absolute;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke: 2px #3f0fff;
    top: -36px;
    left: 0;
    right: 0;
    z-index: -1;
    height: 80px;
    overflow: hidden;
    opacity: 0.27;
}
/* .hero_text{z-index: -1;} */
.hero_text:before {
  content: attr(title);
  position: absolute;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-stroke: 2px #932cff;
  top: -16px;
  left: 0;
  right: 0;
  z-index: -1;
  height: 80px;
  overflow: hidden;
  opacity: 0.45;
}
.hero_text_p {
  position: relative;
  z-index: -1;
}
  .logo{
    height: 90px;
  }
  .button-19{
    width: 50% !important;
  }
  .small{
    width: 280px !important;
  }
  .box{
    margin-bottom: 20px;
  }
  .cards{
    margin-bottom: 20px;
  }
  .circle_about {
    animation: circlethree 10s ease-in-out infinite;
    width: 240px;
    height: 240px;
    position: absolute;
    border-radius: 50%;
    background: linear-gradient(57.75deg, #F70FFF 14.44%, #2C63FF 85.65%);
    top: 109px;
    left: 0px;
    z-index: -1;
    /* overflow: hidden; */
}
.circle::before {
  content: '';
  width: 100%;
  top: initial;
  bottom: initial;
  left: 0;
  right: initial;
  position: absolute;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(57.75deg, #F70FFF 14.44%, #2C63FF 85.65%);
  opacity: 0.84;
  filter: blur(100px);
}
.circle {
  width: 240px;
  height: 240px;
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(57.75deg, #F70FFF 14.44%, #2C63FF 85.65%);
  left: 123px;
}
.text_nfts {
  font-weight: 700;
  font-size: 34px;
  line-height: 56px;
  color: white;
}
.button-19, .button-191 {
  /* appearance: button; */
  /* background-color: #1899D6; */
  background: linear-gradient(57.75deg, #F70FFF 14.44%, #2C63FF 85.65%);
  width: 142px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  border: none;
  /* z-index: -1; */
}
.button-191 span {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: #06060C;
  width: 140px;
  height: 46px;
  position: absolute;
  color: white;
  border-radius: 24px;
  border: none;
  outline: none;
}
.hero_text_font,.btn_explore,.box{
  position: relative;
  z-index: -1;
}
ul{
  margin-top: 100px;
}
.wid_mobile{
  width: 90% !important;
  padding: 20px !important;
}
}
.bg_img {
  position: relative;
}

.bg_img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 25%;
  right: 0;
  bottom: 0;
  z-index: -1; /* Place the background behind the content */
  background-image: url('../src/assets/herosectionbg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -50px center; /* Adjust this to move the image */
  margin-top: -83px;
}

.custom-gap {
  padding-right: 5px; /* Adjust as needed */
  padding-left: 5px;  /* Adjust as needed */
}
.bg_pool{
  background-image: url('../src/assets/bg_pool.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg_pool2{
  background-image: url('../src/assets/Wolf.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Add this CSS to your stylesheet */

/* Truncate text */
.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table_height{
  height: 500px;
  overflow-y: auto;
}
/* Apply the truncate class only on screens smaller than 768px */
@media (max-width: 767.98px) {
  .truncate-text {
      max-width: 150px; /* Adjust this value based on your design */
  }
}
